<template>
  <div class="verify">
      <div class="top">
          OTP Verification
      </div>
      <div class="image">
          <img src="@/assets/images/verify.png" alt="verify">
      </div>
      <p class="otp-mail">Enter the OTP sent to {{ formData.email }}</p>
      <Pincode v-on:next-stage="$emit('inc-counter')" :formData="formData"/>
      <div class="bottom">
        <p>Didn't recieve a code? <a href="#" @click="resendOTP">Resend</a></p>
        <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Verify"/>
        <!-- <div>
            <Button :style="{ color: '#25a244de', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }" text="Previous" @click.prevent="$emit('dec-counter')"/>
        </div> -->
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import Button from '../../../components/Button.vue'
import Pincode from './Pincode.vue'

export default {
    props: {
        formData: Object
    },
    components: {
        Button,
        Pincode
    },
    methods: {
        resendOTP(e) {
            const url = "http://linkmeupsec-001-site1.dtempurl.com"
            e.preventDefault()
            axios.post(`${this.$store.state.apiUrl}/api/Secondary/Account/resend-otp?email=${this.formData.email }`)
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
        }
    }
}
</script>

<style scoped>
    .verify {
        text-align: center;
        width: 300px;
        margin: 0 auto;
        padding-top: 40px;
        /* border: 2px solid red; */
    }

    .top {
        font-weight: 600;
        font-size: 15px;
    }

    .image {
        margin: 20px 0;
    }

    .otp-mail {
        color: #505050;
        width: 200px;
        margin: 0 auto;
    }

    .input {
        display: flex;
        justify-content: center;
        background-color: #f3f3f3;
        padding: 10px;
        border-radius: 30px;
        padding-bottom: 20px;
    }

    .input input{
        width: 50px;
        border: none;
        border-bottom: 2px solid #ccc;
        font-size: 25px;
        margin-right: 10px;
        outline: none;
        background-color: #f3f3f3;
        text-align: center;
    }

    .bottom {
        margin-top: 70px;
        padding: 0 2rem;
    }

    .bottom p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .bottom a {
        color: #06D6A0;
        text-decoration: none
    }
</style>