<template>
  <div class="admin">
      <div>
            <p class="register-msg green">You're almost done!</p>
        </div>

        <div>
          <form autocomplete="OFF" @submit.prevent="$emit('inc-counter')">
            <div>
              <div class="fake-input">
                <select v-model="formData.category" required>
                    <option value="category" selected hidden>Category</option>
                    <option value="home">Home</option>
                    <option value="Estate">Estate</option>
                    <option value="Business">Business</option>
                    <option value="School">School</option>
                </select>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.name" placeholder="Name" required> <i class="fa fa-user"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.postalcode" placeholder="Postal code" required> <i class="fa fa-map-marker" style="color: #0a090887"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.address" placeholder="Address" required> <i class="fa fa-map-marker" style="color: #0a090887"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.website" placeholder="Website" required> <i class="fa fa-globe"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="email" v-model="formData.businessEmailAddress" placeholder="Business email address" required> <i class="fa fa-envelope"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.businessPhoneNumber" placeholder="Business phone number" required> <i class="fa fa-phone"></i>
              </div>
            </div>
          <div class="btn-spot">
              <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Next"/>
            <!-- <div>
                <Button :style="{ color: '#25a244de', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }" text="Previous" @click.prevent="$emit('dec-counter')"/>
            </div> -->
          </div>
          </form>
        </div>
  </div>
</template>

<script>
import Button from '../../../components/Button'
// import axios from 'axios'

export default {
  props: {
    formData: Object
  },
  components: {
      Button
  },
  methods: {
    
  }
}
</script>

<style scoped>
        
    form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

    .register-msg {
        margin: 20px 0;
        margin-top: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }

    .btn-spot {
        width: 250px;
        margin: 20px auto;
    }

    .option {
        font-size: 14px;
        margin-top: 40px;
        text-align: center;
    }

    form input, form select {
      border: none;
    }

    form .fake-input {
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fa-globe, .fa-user, .fa-map-marker, .fa-lock, .fa-envelope, .fa-phone {
      color: #0a090887;
    }

    @media(max-width: 800px) {
    form {
        grid-template-columns: 1fr;
    }
  }
</style>