<template>
    <div class="bar">
        <div class="progressbar">
            <li :class="step === 1 || step === 2 || step === 3 || step === 4 ? 'active' : 'not-active'">Admin details</li>
            <li :class="step === 2 || step === 3 || step === 4 ? 'active' : 'not-active'">Create pin</li>
            <li :class="step === 3 || step === 4 ? 'active' : 'not-active'">Verify OTP</li>
            <li :class="step === 4? 'active' : 'not-active'">Business details</li>
        </div>
    </div>
</template>

<script>
export default {
    props: ['step'],
}
</script>

<style scoped>
.bar {
    padding-bottom: 4rem;
}

.progressbar{
  counter-reset: step;
  margin-bottom: 30px;
  display: block
}
.progressbar li{
  float: left;
  width: 25.0%;
  position: relative;
  text-align: center;
  font-size: 12px;
  list-style: none;
  color: #000;
}

.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #505050;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before{
 border-color: #159661;
 background: #159661;
 transition: 1s;
 color: white
}
.progressbar li.active:after{
 background: #159661;
transition: 1s;
}
/* .progressbar li.active - li:after{
 background: #3aac5d;
transition: 1s;
}
.progressbar li.active - li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  transition: 1s;
  color: white;
} */
.progressbar li:first-child:after{
  content: none;
}

@media(max-width: 769px) {
  .progressbar li {
    width: 33%;
    font-size: 12px;
  }
}
</style>