<template>
  <div class="signup" id="box2">
      <div class="action">
            <router-link to="/security" tag="div" class="head">
                <h1 class="logo"><span class="green large-text-head">Linkmeup </span></h1>
                <div><small class="small-text-head"><b>Security</b></small></div>
            </router-link>
        
            <div class="progress" v-if="step < 5">
                <StepProgress :step="step"/>
            </div>

            <admin-details v-show="step === 1" v-on:inc-counter="incCounter" :formData="formData"/>
            <PIN v-show="step === 2" v-on:inc-counter="incCounter" v-on:dec-counter="decCounter" :formData="formData"/>
            <email-verify v-show="step === 3" v-on:inc-counter="incCounter" v-on:dec-counter="decCounter" :formData="formData"/>
            <business-details v-show="step === 4" v-on:inc-counter="incCounter" v-on:dec-counter="decCounter" :formData="formData"/>
            <Picture v-show="step === 5" v-on:inc-counter="incCounter" v-on:dec-counter="decCounter" :formData="formData"/>
            <Preview v-if="step === 6" :formData="formData" v-on:close-box="$emit('close-box')"/>
      </div>
  </div>
</template>

<script>
import Button from '../../components/Button.vue'
import StepProgress from './components/StepProgress.vue'
import AdminDetails from './components/AdminDetails.vue'
import PIN from './components/PIN.vue'
import EmailVerify from './components/Verify.vue'
import BusinessDetails from './components/BusinessDetails.vue'
import Picture from './components/Picture.vue'
import Preview from './components/Preview.vue'
import platform from 'platform'

export default {
    components: {
        Button,
        StepProgress,
        AdminDetails,
        PIN,
        EmailVerify,
        BusinessDetails,
        Picture,
        Preview
    },
    created() {
        const navigator_info = window.navigator
        const screen_info = window.screen
        let uid = navigator_info.mimeTypes.length
        uid += navigator_info.userAgent.replace(/\D+/g, '');
        uid += navigator_info.plugins.length;
        uid += screen_info.height || '';
        uid += screen_info.width || '';
        uid += screen_info.pixelDepth || '';
        this.formData.device.id = uid
        this.formData.device.type = platform.os.family
    },
    data(){
        return {
            step: 1,
            formData: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                profilePicture: '',
                password: '',
                confirmPassword: '',
                category: 'business',
                name: 'name',
                postalcode: '900001',
                address: 'address',
                website: 'https://hupdev.com',
                businessEmailAddress: 'business@gmail.com',
                businessPhoneNumber: '09081434025',
                pin: '',
                confirmPIN: '',
                tagName: 'business',
                image: '',
                adminId: '',
                device: {
                    id: "",
                    type: ""
                }
            }
        }
    },
    methods: {
        incCounter(){
            if(this.step !== 6) {
                this.step++;
                
            }
        },
        decCounter(){
            if(this.step !== 1){
                this.step--;
            }
        },
    }
}
</script>

<style scoped>
    .progress {
        margin: 10px 0;
        width: 100%;
        display: block;
        /* display: flex;
        justify-content: center; */
    }

    .action {
        flex: 4;
        padding: 2rem 1rem;
    }

    .action h1 {
        text-align: center;
    }

    .head {
        text-align: center;
        color: #25a244de;
        cursor: pointer;
        margin-bottom: 1rem;
        display: block;
        text-decoration: none;
    }

    .large-text-head {
        font-size: 45px;
    }   

    @media(max-width: 800px) {
    .display {
      display: none;
    }

    .progress {
        display: none;
    }
  }
</style>