<template>
  <div class="section">
      <div class="top">
          <div class="loading" v-if="loading">Loading...</div>
          <span class="green">Hi {{ formData.name }}</span>, add logo and choose a unique name tag.
      </div>

    <div class="picture">
        <div class="image-holder" @click="clickBtn">
            <img :src="displayImg" v-if="displayImg">
            <div class="cam-attach flex-center">
            <i class="fa fa-camera"></i>
            </div>
            <input type="file" id="business-picture" hidden @change="onFileChange">
        </div>
    </div>

      <div class="tag">
          <form>
              <label for="tag-name">Enter your name tag</label>
              <div class="fake-input">
                  @<input type="text" v-model="formData.tagName">
              </div>
          </form>
      </div>

      <div class="next bottom">
          <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Next" @click.prevent="submit"/>
        <div>
            <Button :style="{ color: '#25a244de', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }" text="Previous" @click.prevent="$emit('dec-counter')"/>
        </div>
      </div>
  </div>
</template>

<script>
import Button from '../../../components/Button'
import axios from 'axios'

export default {
    props: {
        formData: Object
    },
    components: {
        Button
    },
    data(){
        return {
            loading: false,
            displayImg: 'https://www.pinclipart.com/picdir/big/490-4901349_person-png-animated-cartoon-man-transparent-background-clipart.png'
        }
    },
    methods: {   
        clickBtn(){
            document.getElementById('business-picture').click()
        },
        onFileChange(e){
            const files = e.target.files || e.dataTransfer.files;
            if(!files.length)
                return
            this.formData.image = files[0]
            this.createImage(files[0])
            console.log(123)
        },
        createImage(file) {
            // const image = new Image();
            const reader = new FileReader();
            reader.onload = (e) => {
                this.displayImg = e.target.result;
                // console.log(e.target.result)
            };
            reader.readAsDataURL(file)
        }, 
        submit(){
            const url = "http://linkmeupsec-001-site1.dtempurl.com"
            this.loading = true
            const { 
                category, name, postalcode, address, website, businessPhoneNumber, businessEmailAddress, tagName, image, adminId } = this.formData;
                const formData = new FormData();
                formData.append("AdminId", adminId)
                formData.append("SecondaryCategoryName", name)
                formData.append("SecondaryCategoryPicture", image)
                formData.append("SecondaryCategoryNameTag", tagName)
                formData.append("SecondaryCategoryType", category)
                formData.append("SecondaryCategoryPostalCode", postalcode)
                formData.append("SecondaryCategoryAddress", address)
                formData.append("SecondaryCategoryWebsite", website)
                formData.append("SecondaryCategoryEmailAddress", businessEmailAddress)
                formData.append("SecondaryCategoryPhoneNumber", businessPhoneNumber)
            
                axios.post(`${this.$store.state.apiUrl}/api/Secondary/Account/complete-register`, formData)
                .then(res => {
                    if(res.data.code == 200) {
                        this.$emit('inc-counter')
                    }
                    console.log(res.data)
                    
                    this.loading = false
                })
                .catch(err => {
                    console.log(err.response.data)
                    this.loading = false
                })
                console.log(this.formData)
            }
    }
}
</script>

<style scoped>
    .top {
        padding: 30px 0;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }

    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        width: 100px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }

    .cam-attach {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: #25a244de;
        color: #fff;
        text-align:center;
        cursor: pointer;
    }

    .tag {
        width: 300px;
        margin: 0 auto;
        padding-top: 30px;
    }

    .tag input {
        /* border-radius: 20px; */
        border:none;
        padding: 10px 0;
        color: #25a244de;
    }

    .fake-input {
        display: flex;
        align-items:center;
        border: 1px solid #ccc;
        padding: 0 10px;
        border-radius: 20px;
        color: #25a244de;
    }

    .bottom {
        width: 300px;
        margin: 0 auto;
        padding-top: 80px;
    }

    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        width: 100px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }

    .cam-attach {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: #25a244de;
        color: #fff;
        text-align:center;
        cursor: pointer;
    }
</style>