<template>
  <div class="admin">
      <div>
            <p class="register-msg">Complete this process to get started</p>
        </div>

        <div>
          <form autocomplete="OFF" @submit.prevent="!passwordError() || !passwordLengthError() ? $emit('inc-counter') : null">
            <PictureHolder :formData="formData" :imageType="1"/>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.firstName" placeholder="First name" required> <i class="fa fa-user"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.lastName" placeholder="Last name" required> <i class="fa fa-user"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="text" v-model="formData.phoneNumber" placeholder="phone number" required> <i class="fa fa-phone"></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="email" v-model="formData.email" placeholder="Email" required> <i class="fa fa-envelope"></i>
              </div>
            </div>
            <div>
              <div class="fake-input" :style="passwordLengthError() ? 'border-bottom: 1px solid red' : ''">
                <input type="password" v-model="formData.password" placeholder="Password" required> <i class="fa fa-lock"></i>
              </div>
              <div class="error" v-if="passwordLengthError()">Password must be 8 characters long</div>
            </div>
            <div>
              <div class="fake-input" :style="passwordError() ? 'border-bottom: 1px solid red' : ''">
                <input type="password" v-model="formData.confirmPassword" placeholder="Confirm Password" required> <i class="fa fa-lock"></i>
              </div>
              <div class="error" v-if="passwordError()">Passwords do not match</div>
            </div>
          <div class="btn-spot">
            <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Next"/>
          </div>
          </form>
          <div class="option">
              Already registered? <router-link to="/security/login">Login</router-link>
          </div>
        </div>
  </div>
</template>

<script>
import Button from '../../../components/Button'
import PictureHolder from '../../../components/PictureHolder'

export default {
  props: {
    formData: Object
  },  
  components: {
      Button,
      PictureHolder
  },
  data() {
    return {
      passwordError: () => {
        return this.formData.password !== this.formData.confirmPassword
      },
      passwordLengthError: () => {
        return this.formData.password.length < 8;
      }
    }
  }
}
</script>

<style scoped>
        
    form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

    .register-msg {
        margin: 20px 0;
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
    }

    .btn-spot {
        width: 250px;
        margin: 20px auto;
    }

    .error {
      color: red;
      font-size: 13px;
    }

    .option {
        font-size: 14px;
        margin-top: 40px;
        text-align: center;
    }

    .option a {
        color: #06D6A0;
        text-decoration: none
    }

    form input {
    border: none;
  }

  form input:focus {
    border-bottom: 1px solid #159661;
    border-radius: 0;
  }

  form .fake-input {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form .fa-user, form .fa-lock, .fa-envelope, .fa-phone {
    color: #0a090887;
  }

    @media(max-width: 800px) {
    form {
        grid-template-columns: 1fr;
    }
  }
</style>