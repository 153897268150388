<template>
  <div class="preview">
      <div class="picture">
          <div class="image-holder" @click="clickBtn">
              <img :src="img" alt="image">
          </div>
      </div>

      <div class="tag">
          <h4>{{ formData.name }}</h4>
          <p class="tagname">@{{ formData.tagName }}</p>
      </div>

      <div class="msg">
          <h4>Hi {{ formData.name }}👋</h4>
          <p>Your profile is ready! you can add more info in preferences</p>
      </div>

      <div class="verify">
          <div>
              <i class="fa fa-check"></i>
              <p>Email verification</p>
          </div>
          <div>
              <i class="fa fa-check"></i>
              <p>Business profile</p>
          </div>
      </div>

      <div class="bottom">
        <Button @click="$router.push('/security/login')" :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Finish" />
      </div>
  </div>
</template>

<script>
import Button from '../../../components/Button.vue'

export default {
    props: {
        formData: Object
    },
    components: {
        Button
    },
    created(){        
        this.createImage(this.formData.image)
        console.log(this.formData.image)
    },
    methods: {
        createImage(file) {
            // const image = new Image();
            const reader = new FileReader();
            reader.onload = (e) => {
                this.img = e.target.result;
            };
            reader.readAsDataURL(file)
        },
    },
    data(){
        return {
            img: ''
        }
    },
}
</script>

<style scoped>
    .preview {
        text-align: center;
        width: 340px;
        margin: 20px auto;
    }

    .preview > div {
        margin-bottom: 30px;
    }

    .image-holder {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        width: 100px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }   

    .msg p {
        font-size: 14px;
        width: 270px;
        margin: 0 auto;
        color: #444444e5;
    }

    .verify {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .verify > div {
        display: flex;
        align-items: center;
        color: #25a244de;
        margin-bottom: 15px;
        /* jusify-content: space-between; */
    }

    .verify p {
        margin-left: 10px;
    }

    .fa-check {
        border-bottom: 2px solid #25a244de;
    }

    .tagname {
        font-size: 13px;
    }

    .bottom {
        margin-top: 100px;
    }
</style>