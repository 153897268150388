<template>
  <div class="pin">
      <div>
          <small>Please enter your secret PIN to validate your information</small>
      </div>
      <div class="image">
          <img src="@/assets/images/pins.png" alt="pin">
      </div>
      <form class="inputs">
        <div>
            <label>Enter pin</label>
            <div class="input-box">
                <input :type="revealPin ? 'text' : 'password'" id="pin" v-model="formData.pin">
                <span class="reveal" v-if="revealPin" @click="revealPin = false"><i class="fa fa-eye"></i></span>
                <span class="reveal" v-else @click="revealPin = true"><i class="fa fa-eye-slash"></i></span>
            </div>
        </div>
        <div>
            <label>Confirm pin</label>
            <div class="input-box">
                <input :type="revealConfirmPin ? 'text' : 'password'" 
                       id="confirm-pin" 
                       v-model="formData.confirmPIN" 
                       :style="formData.pin !== formData.confirmPIN ? 'border: 1px solid #ccc': null">
                <span class="reveal" v-if="revealConfirmPin" @click="revealConfirmPin = false"><i class="fa fa-eye"></i></span>
                <span class="reveal" v-else @click="revealConfirmPin = true"><i class="fa fa-eye-slash"></i></span>
            </div>
            <div class="errors">
                <div class="flex" v-for="(error, index) in errorMsgs" :key="index" v-show="error !== ''">
                    <span class="error-name">{{ error[0] }}</span>
                    <span style="cursor: pointer" @click="cancelError(index)"> <i class="fa fa-times"></i> </span>
                </div>
            </div>
        </div>
        <div>
            <!-- <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Next" @click.prevent="$emit('inc-counter')"/> -->
        </div>
      </form>
      <div class="bottom">
        <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%', borderRadius: '20px' }" text="Create Pin" @click.prevent="createPin"/>
        <div>
            <Button :style="{ color: '#25a244de', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }" text="Previous" @click.prevent="$emit('dec-counter')"/>
        </div>
      </div>
  </div>
</template>

<script>
import Button from '../../../components/Button.vue'
import axios from 'axios'

export default {
    props: {
        formData: Object
    },  
    components: {
        Button
    },
    data() {
        return {
            revealPin: false,
            revealConfirmPin: false,
            errorMsgs: [
                []
            ],
            loading: false
        }
    },
    methods: {
        createPin(){
            this.loading = true
            const { firstName, lastName, phoneNumber, email, password, confirmPassword, pin, confirmPIN, profilePicture, device } = this.formData;
                const formData = new FormData();
                formData.append("FirstName", firstName)
                formData.append("LastName", lastName)
                formData.append("Email", email)
                formData.append("PIN", pin)
                formData.append("ConfirmPIN", confirmPIN)
                formData.append("PhoneNumber", phoneNumber)
                formData.append("Password", password)
                formData.append("ConfirmPassword", confirmPassword)
                formData.append("ProfilePicture", profilePicture)
                formData.append("DeviceId", device.id)
                formData.append("DeviceType", device.type)

                console.log(this.formData)

            axios.post(`${this.$store.state.apiUrl}/api/Secondary/Account/register`, formData)
            .then(res => {
                if(res.data.code == 200) {
                    console.log(res.data)
                    this.formData.adminId = res.data.data.adminId
                    this.loading = false
                    this.$emit('inc-counter')
                }
            })
            .catch(err => {
                this.loading = false
                console.log(err)
                this.errorMsgs = err.response.data.errors || [["Something went wrong"]]
                // if(!err.response.data.errors) {
                !err.response.data.errors ? this.errorMsgs.push([err.response.data?.ShortDescription]) : null
                // }
            })
        },
        cancelError(index){
            this.errorMsgs = this.errorMsgs.filter((error, i) => i !== index)
        }
    }
}
</script>

<style scoped>
    .pin {
        width: 300px;
        margin: 0 auto;
        padding-top: 40px;
    }

    .pin > div {
        text-align: center;
    }

    .top {
        font-weight: 600;
        font-size: 15px;
    }

    .bottom {
        margin-top: 70px;
        padding: 0 2rem;
    }

    .bottom p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .bottom a {
        color: #06D6A0;
        text-decoration: none
    }

    form > div {
        margin-bottom: 20px;
    }

    form input {
      margin-top: 2px;
      border: 1px solid #a7a7a7;
      font-size: 15px;  
      width: 300px;
    }

    .input-box {
        position: relative;
    }

    .reveal {
        position: absolute;
        right: 5px;
        top: 12px;
        cursor: pointer;
    }

    .errors > div {
        margin-bottom: 5px;
        padding: 10px;
        background-color: #e44444;
        font-size: 14px;
        border-radius: 3px;
    }

    .errors {
        position: absolute;
        top: 10%;
        left: 25px;
        width: 90%;
        color: #fff;
    }
</style>