<template>
  <div id="pincode">
  <div class="captcha">
    <input v-for="(c, index) in ct" :key="index"
           type="text" v-model="ct[index]" ref="input"
           :style="{borderBottomColor: index <= cIndex ? '#25a244de' : ''}"
           @input="e => {onInput(e.target.value, index)}"
           @keydown.delete="e=>{onKeydown(e.target.value, index)}"
           @focus="onFocus"
           :disabled="loading"
           >
  </div>
  <p class="error">{{msg}}</p>
</div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    formData: Object
  },
  data() {
    return {
      ct: ['','','',''],
      loading: false,
      msg:'',
    }
  },
  computed: {
    ctSize() {
      return this.ct.length;
    },
    cIndex() {
      let i = this.ct.findIndex(item => item ==='');
      i = (i + this.ctSize)% this.ctSize;
      return i;
    },
    lastCode() {
      return this.ct[this.ctSize-1];
    }
  },
  watch: {
    cIndex() {
      this.resetCaret();
    },
    lastCode(val) {
      if (val) {
        // console.log('this.ctSize', this.ctSize)
        this.$refs.input[this.ctSize-1].blur();
        this.sendCaptcha();
      }
    }
  },
  mounted() {
    this.resetCaret();
  },
  methods: {
    onInput(val, index) {
      this.msg =''
      val = val.replace(/\s/g,'');
      if (index == this.ctSize-1) {
        this.ct[this.ctSize-1] = val[0]; // The last code, only one character is allowed.
      } else if(val.length> 1) {
        let i = index;
        for (i = index; i <this.ctSize && i-index <val.length; i++) {
          this.ct[i] = val[i];
        }
        this.resetCaret();
      }
    },
    // Reset the cursor position.
    resetCaret() {
      this.$refs.input[this.ctSize-1].focus();
    },
    onFocus() {
      // Listen to the focus event and reposition the cursor to "the position of the first blank character".
      let index = this.ct.findIndex(item => item ==='');
      index = (index + this.ctSize)% this.ctSize;
      this.$refs.input[index].focus();
    },
    onKeydown(val, index) {
      if (val ==='') {
        // Delete the value in the previous input and focus on it.
        if (index> 0) {
          this.ct[index-1] ='';
          this.$refs.input[index-1].focus();
        }
      }
      
    },
    sendCaptcha() {
      // Input cannot be operated at this time. .
      const url = "http://linkmeupsec-001-site1.dtempurl.com"

      this.loading = true;
      axios.post(`${this.$store.state.apiUrl}/api/Secondary/Account/validate-otp`, {
        email: this.formData.email,
        otpCode: this.ct.join('')
      })
      .then(res => {
        console.log(res.data)
        this.$emit('next-stage')
        this.loading = false
      })
      .catch(err => {
        console.log(err.response.data)
        this.msg = (err.response.data.ShortDescription)
        this.loading = false;
        this.$nextTick(() => {
          this.reset();
        })
      })
      
    },
     
    reset() {
      // Reset. It is usually triggered when the verification code is wrong.
      this.ct = this.ct.map(item =>'');
      this.resetCaret();
    }
  }
}
</script>

<style scoped>
    .captcha {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
input {
  margin-right: 20px;
  width: 25px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: 600;
  outline: none;
}
input:last-of-type {
  margin-right: 0;
}

input:focus {
    border-bottom: 1px solid #25a244de;
}

input:disabled {
  color: #000;
  background-color: #fff;
}
.msg {
  text-align: center;
}

.error {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}
</style>