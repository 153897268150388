<template>
  <div class="picture">
    <div class="image-holder" @click="clickBtn">
        <img :src="displayImg" v-if="displayImg">
        <div class="cam-attach flex-center">
        <i class="fa fa-camera"></i>
        </div>
        <input type="file" id="profile-picture" hidden @change="onFileChange">
    </div>
</div>
</template>

<script>
export default {
    props: ['formData', 'imageType'],
    data(){
        return {
            displayImg: 'https://www.pinclipart.com/picdir/big/490-4901349_person-png-animated-cartoon-man-transparent-background-clipart.png'
        }
    },
    methods: {
        clickBtn(){
            document.getElementById('profile-picture').click()
        },
        onFileChange(e){
            const files = e.target.files || e.dataTransfer.files;
            if(!files.length)
                return
            if(this.imageType == 1) {
                this.formData.profilePicture = files[0]
            } else if(this.imageType == 0) {
                this.formData.image = files[0]
            }
            this.createImage(files[0])
        },
        createImage(file) {
            // const image = new Image();
            const reader = new FileReader();
            reader.onload = (e) => {
                this.displayImg = e.target.result;
                // console.log(e.target.result)
            };
            reader.readAsDataURL(file)
        }
    }
}
</script>

<style scoped>
    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        width: 100px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }

    .cam-attach {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: #25a244de;
        color: #fff;
        text-align:center;
        cursor: pointer;
    }
</style>